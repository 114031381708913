import PhotoSwipeLightbox from "photoswipe/lightbox"

document.addEventListener("DOMContentLoaded", (e) => {
  const lightbox = new PhotoSwipeLightbox({
    gallery: "#photos",
    children: 'a',
    pswpModule: () => import('photoswipe')
  })

  lightbox.init()

  const onHashChanged = () => {
    if (window.location.hash === "#photos") {
      return
    }

    console.log("hashchange", window.location.hash)

    lightbox.pswp.close()
  }
  const onClose = () => {
    window.removeEventListener("hashchange", onHashChanged)
    if (window.location.hash === "#photos") {
      window.history.back()
    }
  }
  const onBeforeOpen = () => {
    window.location.hash = "#photos"
    window.addEventListener("hashchange", onHashChanged)
  }
  lightbox.on("beforeOpen", onBeforeOpen)
  lightbox.on("close", onClose)
})

document.addEventListener("DOMContentLoaded", (e) => {
  document.querySelectorAll("a[href^=\"#\"]").forEach(anchor => {
    anchor.addEventListener("click", (e) => {
      e.preventDefault()

      document.querySelector(e.target.getAttribute("href")).scrollIntoView({
          behavior: "smooth"
      })
    })
  })
})

document.addEventListener("DOMContentLoaded", () => {
  const target = document.querySelector("#inicio")
  const nav = target.querySelector("#nav")
  const handleEntry = (entry) => {
    if (entry.isIntersecting) {
      nav.removeAttribute("data-scrolled")
    }
    else {
      nav.setAttribute("data-scrolled", true)
    }
  }
  const handleEntries = (entries) => {
    entries.forEach(handleEntry)
  }
  const options = { threshold: 0.25 }
  const observer = new IntersectionObserver(handleEntries, options)
  observer.observe(target)
})

document.addEventListener("DOMContentLoaded", () => {
  const dropdown = document.querySelector("#dropdown")
  const button = dropdown.querySelector("#dropdown-button")
  const onClickOut = (e) => {
    dropdown.removeAttribute("data-openned")
  }
  const onClickButton = (e) => {
    dropdown.setAttribute("data-openned", true)
    e.stopPropagation()
    window.addEventListener("click", onClickOut, { once: true })
  }
  button.addEventListener("click", onClickButton)
})

document.addEventListener("DOMContentLoaded", () => {
  const target = document.querySelector("#sobre")
  const video = target.querySelector("#video")
  const handleEntry = (entry) => {
    if (entry.isIntersecting) {
      video.play()
    }
    else {
      video.currentTime = 0
      video.pause()
    }
  }
  const handleEntries = (entries) => {
    entries.forEach(handleEntry)
  }
  const options = { threshold: 0.25 }
  const observer = new IntersectionObserver(handleEntries, options)
  observer.observe(target)
})
